$(document).on('turbo:load', function() {
  $('#job_company_name').autocomplete({
    source: $('#job_company_name').data('autocomplete-source'),
    select: function(event, ui) {
      $("#job_company_url").val(ui.item.url);
    }
  });

  $('.reminder-modal').click(function() {
    $('#follow_up_reminder_entry_id').val($(this).data('entryId'));
  });

  $('.entry-toggle').click(function() {
    console.log('toggled')
    $(this).closest('.entry').find('.entry-truncated').toggle();
    $(this).closest('.entry').find('.entry-full').toggle();
  });

});